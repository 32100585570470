
import { useEdiItemCodeMappingMaintenanceTracked } from "presentation/store/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import EdiItemCodeMappingMaintenance from "./EdiItemCodeMappingMaintenance";
import { EdiItemCodeMappingMaintenanceFormPanel } from "./EdiItemCodeMappingMaintenanceFormPanel";

export const EdiItemCodeMappingMasterView: React.FC = () => {
    const [ediItemCodeMappingState] = useEdiItemCodeMappingMaintenanceTracked();
    const { isAdd, isEditable } = ediItemCodeMappingState.masterState;

    return <>
        <div className="main-comp-wrapper">
            <EdiItemCodeMappingMaintenance/>
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<EdiItemCodeMappingMaintenanceFormPanel/>}
                />
                )
            }
        </div>
    </>
}