
import { EdiItemCodeMappingEntity, EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY } from "domain/entity/EdiItemCodeMapping/EdiItemCodeMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface EdiItemCodeMappingDropdownOptions {
    companyCodeDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: {[key: string]: DropdownProps[]};
    cntrSizeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: EdiItemCodeMappingEntity,
}

export interface EdiItemCodeMappingMaintenanceModel {
    isLoading: boolean,
    tableData: EdiItemCodeMappingEntity[],
    currentSelectedRow: EdiItemCodeMappingEntity,
    selectedRows: EdiItemCodeMappingEntity[],
    dynamicOptions: EdiItemCodeMappingDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
}

export const EMPTY_EDI_ITEM_CODE_MAPPING_MODEL: EdiItemCodeMappingMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        companyCodeDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        cntrSizeDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY,
    },
    isBackMaster: false,
}