import { SelectionChangedEvent } from "ag-grid-community";
import { EdiItemCodeMappingEntity } from "domain/entity/EdiItemCodeMapping/EdiItemCodeMappingEntity";
import { INITIAL_EDI_ITEM_CODE_MAPPING_COL_DEF } from "presentation/constant/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceColumnDefinition";
import { EdiItemCodeMappingMaintenanceConstant } from "presentation/constant/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceConstant";
import { useEdiItemCodeMappingMaintenanceVM } from "presentation/hook/EdiItemCodeMapping/useEdiItemCodeMappingMaintenanceVM";
import { useEdiItemCodeMappingMaintenanceTracked } from "presentation/store/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const EdiItemCodeMappingTablePanel:React.FC = () => {
    const [ediItemCodeMappingState] = useEdiItemCodeMappingMaintenanceTracked();
    const ediItemCodeMappingVM = useEdiItemCodeMappingMaintenanceVM();
    const gridRef: any = useRef(null);
    const {tableData} = ediItemCodeMappingState;

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        ediItemCodeMappingVM.updateSelectedRows(tableData,selectedRows);
    }, [ediItemCodeMappingVM, tableData])

    const handleAddClick = useCallback(() => {
        ediItemCodeMappingVM.onAdd();
    }, [ediItemCodeMappingVM])

    const handleRowDoubleClick = useCallback((entity: EdiItemCodeMappingEntity) => {
          ediItemCodeMappingVM.onEdit(entity);
    }, [ediItemCodeMappingVM])

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='edi_item_code_mapping-table'
            headerLabel={EdiItemCodeMappingMaintenanceConstant.TITLE}
            isNewColumnSetting={true}
            columns={INITIAL_EDI_ITEM_CODE_MAPPING_COL_DEF}
            data={tableData??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={true}
            onAddClick={handleAddClick}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: EdiItemCodeMappingEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(EdiItemCodeMappingTablePanel);
