import { DocumentConst } from "constants/document/DocumentConst";
import { EdiItemCodeMappingMaintenanceConstant } from "presentation/constant/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceConstant";
import { ediItemCodeTypeRadioOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { useEdiItemCodeMappingMaintenanceVM } from "presentation/hook/EdiItemCodeMapping/useEdiItemCodeMappingMaintenanceVM";
import { useEdiItemCodeMappingMaintenanceTracked } from "presentation/store/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useEffect, useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import EdiItemCodeMappingEditPanelTitleBar from "./EdiItemCodeMappingEditPanelTitleBar";

export const EdiItemCodeMappingMaintenanceFormPanel = () => {
    const [ediItemCodeMappingState] = useEdiItemCodeMappingMaintenanceTracked();
    const ediItemCodeMappingVM = useEdiItemCodeMappingMaintenanceVM();
    const { currentSelectedRow, masterState,dynamicOptions } = ediItemCodeMappingState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = EdiItemCodeMappingMaintenanceConstant;


    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const memoTariffCodeOptions = useMemo(() => {  
        return currentEntity.tariffType  
            ? dynamicOptions.tariffCodeDropdownOptions[currentEntity.tariffType]  
            : [];  
    }, [currentEntity.tariffType, dynamicOptions.tariffCodeDropdownOptions]);

    

    useEffect(() => {
        ediItemCodeMappingVM.onItemCodeTypeChanged();
    },[ediItemCodeMappingVM,currentEntity.itemCodeType])

    const memoBillTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.billToCompany || ''}
                fieldValue={currentEntity?.billToCompany || ''}
                fieldLabel={SCREEN_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    ediItemCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.billToCompany, SCREEN_CONSTANT.BILL_TO_COMPANY, isSaveClicked, dynamicOptions.companyCodeDropdownOptions, ediItemCodeMappingVM])

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffType || ''}
                fieldValue={currentEntity?.tariffType || ''}
                fieldLabel={SCREEN_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    ediItemCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.tariffType, SCREEN_CONSTANT.TARIFF_TYPE, isSaveClicked, dynamicOptions.tariffTypeDropdownOptions, ediItemCodeMappingVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffCode || ''}
                fieldValue={currentEntity?.tariffCode || ''}
                fieldLabel={SCREEN_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                isShowMissingError={true}
                maxLength={10}
                options={memoTariffCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    ediItemCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.tariffCode, SCREEN_CONSTANT.TARIFF_CODE, isSaveClicked, memoTariffCodeOptions, ediItemCodeMappingVM])


    const memoCntrSize = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.cntrSize || ''}
                fieldValue={currentEntity?.cntrSize || ''}
                fieldLabel={SCREEN_CONSTANT.CONTAINER_SIZE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrSize'}
                isShowMissingError={true}
                options={dynamicOptions.cntrSizeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    ediItemCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.cntrSize, SCREEN_CONSTANT.CONTAINER_SIZE, isSaveClicked, dynamicOptions.cntrSizeDropdownOptions, ediItemCodeMappingVM])

    const memoItemCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "390px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.ediItemCode || ''}
                fieldValue={currentEntity?.ediItemCode || ''}
                fieldLabel={SCREEN_CONSTANT.ITEM_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'ediItemCode'}
                isShowMissingError={true}
                maxLength={30}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    ediItemCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.ediItemCode, SCREEN_CONSTANT.ITEM_CODE, isSaveClicked, ediItemCodeMappingVM])

    const memoItemCodeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "390px", marginBottom: "24px" }}>

           <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.itemCodeType || ''}
                fieldValue={currentEntity?.itemCodeType}
                fieldLabel={SCREEN_CONSTANT.ITEM_CODE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.RADIO_GROUP}
                fieldKey={'itemCodeType'}
                isShowMissingError={true}
                maxLength={10}
                options={ediItemCodeTypeRadioOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    ediItemCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> 
                    
        </div>
        , [allFormState, isRead, currentEntity?.itemCodeType, SCREEN_CONSTANT.ITEM_CODE_TYPE, isSaveClicked, ediItemCodeMappingVM])

    const memoDisplayTarExt = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"displayTariffExtension"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.DISPLAY_TARIFF_EXTENSION}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.displayTariffExtension === 'Y'}
                    disabled={isRead}
                    onChange={(e) => ediItemCodeMappingVM.onCheckboxChange(e.checked, "displayTariffExtension")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.DISPLAY_TARIFF_EXTENSION, currentEntity?.displayTariffExtension, isRead, ediItemCodeMappingVM])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => ediItemCodeMappingVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, ediItemCodeMappingVM])

    const memoDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "390px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.recommendation23Desc || ''}
                fieldValue={currentEntity?.recommendation23Desc || ''}
                fieldLabel={SCREEN_CONSTANT.DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'recommendation23Desc'}
                isShowMissingError={true}
                maxLength={500}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    ediItemCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.recommendation23Desc, SCREEN_CONSTANT.DESC, isSaveClicked, ediItemCodeMappingVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <EdiItemCodeMappingEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{!isAdd ? currentEntity.ediItemCode : "NEW CODE"}</SidebarTitle>

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoBillTo} 
                        {currentEntity.itemCodeType === DocumentConst.ediItemCodeTypePolicy.RECOMMENDATION23 && memoCntrSize}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoTarType} {memoTarCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoItemCode} 
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoItemCodeType}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {currentEntity.itemCodeType === DocumentConst.ediItemCodeTypePolicy.RECOMMENDATION23 && memoDesc}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoDisplayTarExt} {memoActiveInd}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
